// body {
//   margin: 0;
//   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
// }
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
  user-select: none;
  /* font-family: myFirstFont; */
}
a { text-decoration: none !important; }
.loading {
  text-align: center;
  margin: auto;
  padding: 25%;
}
/* Define an animation behavior */
@keyframes spinner {
  to { transform: rotate(360deg); }
}
/* This is the class name given by the Font Awesome component when icon contains 'spinner' */
.spinner {
  /* Apply 'spinner' keyframes looping once every second (1s)  */
  animation: spinner 1s linear infinite;
}

/* insta feed style */
._2R-kh {
  margin: 5% 0;
  justify-content: center !important;
}
._wPDyp {
  flex-basis: calc(100%/3 - 20px) !important;
  margin: 10px !important;
}
._3xnQP:hover {
  border-radius: 10px !important;
}
._vzTHL {
  border-radius: 10px !important;
  box-shadow: 0px 4px 8px 0px #ccc;
}
.instagram-count-item .icon svg {
  height: 45px !important;
  width: 45px;
}
.amazon-pic {
  text-align: left;
}
.amazon-pic img {
  width: 150%;
}
// img{ 
//   pointer-events: none;
// }